import { dot, norm } from "mathjs"

const mathsHelpers = {
  dotproduct: (a, b) => a.map((x, i) => a[i] * b[i]).reduce((m, n) => m + n),
  transpose: (a) => a[0].map((x, i) => a.map(y => y[i])),

  mmultiply(a,b) {
    return a.map(x => this.transpose(b).map(y => this.dotproduct(x, y)))
  },
  euclideanDistance(a, b) {
    return Math.hypot(...Object.keys(a).map(k => b[k] - a[k]))
  },

  cosineSimilarity(embedding1, embedding2) {
    let dotProduct = dot(embedding1, embedding2)
    let norm1 = norm(embedding1)
    let norm2 = norm(embedding2)
    return dotProduct / (norm1 * norm2)
  },

  /**
   * Clamps a number within a range.
   * @param {number} number - The number to clamp.
   * @param {number} min - The lower bound of the range.
   * @param {number} max - The upper bound of the range.
   * @returns {number} The clamped number.
   */
  clamp(number, min, max) {
    return Math.max(min, Math.min(number, max))
  },

}

export default mathsHelpers
