<template>
  <div>
    <v-snackbar
      v-for="(notification, index) in appNotifications.filter(n => n.show)"
      :key="notification.uuid"
      v-model="notification.show"
      :timeout="notification.timeout"
      top
      text
      right
      :color="colors[notification.type]"
      :style="`top: ${(index * 60) + 8}px`"
    >
      {{ notification.message }}
      <template #action="{ attrs }">
        <v-btn
          :color="colors[notification.type]"
          text
          v-bind="attrs"
          @click="notification.show = false"
          icon
          small
        >
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import { mapGetters } from "vuex"

export default {
  name: "AppNotifications",
  data() {
    return {
      snackbar: true,
      colors: {
        success: "success",
        error: "error",
        info: "info",
      },
    }
  },
  computed:{
    ...mapGetters(["appNotifications"]),
  },
}
</script>

<style scoped>

</style>
